import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h3>dc4p presents:</h3>
        <p>The Swift 'Accordion' - Language Guide</p>
      
        <iframe width="560" 
                height="315" 
                src="https://www.youtube.com/embed/Yq-bfM4OxxE?si=tqSzO-W4KLlKFzqf" 
                title="YouTube video player" 
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen>
        </iframe>

         
        <script src="https://gumroad.com/js/gumroad.js"></script>
       

        <button class="button">
          <a
            href="https://dc4p.gumroad.com/l/swift-lang-accordion" 
            data-gumroad-overlay-checkout="true">I want my accordion
          </a>
        </button>
      </header>
    </div>
  );
}

export default App;
